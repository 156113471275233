import styled from '@emotion/styled'
import { createElement as $, FC, Fragment, useEffect, useState } from 'react'
import 'sanitize.css'
import { Client, Direction, Server, Client_Motor } from './main'
import { useWebSocketClient, WebSocketProvider } from './WebSocketProvider'

const App = () => {
  const url = window.location.pathname === '/'
    ? 'ws://localhost:4141'
    : `ws://${window.location.pathname.slice(1)}`
  return $(WebSocketProvider, { url },
    $(Container, null, $(Main)))
}

const Main = () => {
  const ws = useWebSocketClient()
  if ([-1, 0].includes(ws.readyState))
    return  $(Status, null, `Connecting to ${ws.getWebSocket()?.url}`)
  if ([2, 3].includes(ws.readyState))
    return $(Status, null, `Cannot connect to the server on ${ws.getWebSocket()?.url}. Is it running?`)
  return $(Fragment, null,
    $(Holder, null,
      $(Button, {
        label: 'Главный мотор внутрь',
        move: {
          speed: 10,
          direction: Direction.INWARD,
          motor: Client_Motor.MAIN
        }
      }),
      $(Button, {
        label: 'Главный мотор наружу',
        move: {
          speed: 10,
          direction: Direction.OUTWARD,
          motor: Client_Motor.MAIN
        }
      })),
    $(Holder, null,
      $(Button, {
        label: 'Мотор пластины внутрь',
        move: {
          speed: 10,
          direction: Direction.INWARD,
          motor: Client_Motor.CLAMP
        }
      }),
      $(Button, {
        label: 'Мотор пластины наружу',
        move: {
          speed: 10,
          direction: Direction.OUTWARD,
          motor: Client_Motor.CLAMP
        }
      })),
    $(Holder, null,
      $(Button, {
        label: 'Мотор головы внутрь',
        move: {
          speed: 10,
          direction: Direction.INWARD,
          motor: Client_Motor.HEAD
        }
      }),
      $(Button, {
        label: 'Мотор головы наружу',
        move: {
          speed: 10,
          direction: Direction.OUTWARD,
          motor: Client_Motor.HEAD
        }
      })),
    $(Holder, null,
      $(Button, {
        label: 'Полный цикл',
        cycle: {}
      }),
      $(Button, {
        label: 'Остановка',
        stop: {}
      })),
    $(Log))
}

const Container = styled.div({
  backgroundColor: 'black',
  color: 'white',
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  fontFamily: 'sans-serif'
})

const Log = () => {
  const ws = useWebSocketClient()
  const [log, setLog] = useState<any[]>([])
  useEffect(() => {
    if (ws.lastMessage) {
      const bytes = new Uint8Array(ws.lastMessage.data)
      const message = Server.toJSON(Server.decode(bytes))
      setLog((log) => [message, ...log])
    }
  }, [ws.lastMessage])
  return $(LogContainer, null,
    log.map((message, index) =>
      $(LogEntry, { key: index }, JSON.stringify(message))))
}

const Status = styled.div({
  padding: '16px'
})

const LogEntry = styled.div({
  padding: '8px 16px',
  fontFamily: 'monospace'
})

const LogContainer = styled.div({
  flexGrow: 1,
  overflowY: 'auto'
})

const Holder = styled.div({
  display: 'flex',
  padding: '16px',
  flexWrap: 'wrap',
  gap: '1rem'
})

const Button: FC<{ label: string } & Client> = ({
  label,
  ...rest
}) => {
  const ws = useWebSocketClient()
  const message = Client.encode(rest).finish()
  return $(ButtonContainer, { onClick: () => ws.sendMessage(message) }, label)
}

const ButtonContainer = styled.div({
  padding: '1rem',
  background: 'blue',
  borderRadius: '1rem'
})

export default App