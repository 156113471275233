import { createContext, createElement as $, FC, PropsWithChildren, useContext, useEffect } from 'react'
import useWebSocket from 'react-use-websocket'
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';

export const webSocketContext = createContext<WebSocketHook<any | null, MessageEvent<any> | null>>({
  sendMessage: (message: any) => undefined,
  sendJsonMessage: (message: any) => undefined,
  lastMessage: null,
  lastJsonMessage: null,
  readyState: 0,
  getWebSocket: () => null
})

export const WebSocketProvider: FC<PropsWithChildren<{ url: string }>> = ({ children, url }) => {
  const value = useWebSocket(url)
  useEffect(() => {
    const webSocket = value.getWebSocket()
    if (webSocket)
      // @ts-ignore
      webSocket.binaryType = 'arraybuffer'
  })
  return $(webSocketContext.Provider, { value }, children)
}

export const useWebSocketClient = () => {
  const ws = useContext(webSocketContext)
  return ws
}