/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum Direction {
  INWARD = 0,
  OUTWARD = 1,
  UNRECOGNIZED = -1,
}

export function directionFromJSON(object: any): Direction {
  switch (object) {
    case 0:
    case "INWARD":
      return Direction.INWARD;
    case 1:
    case "OUTWARD":
      return Direction.OUTWARD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Direction.UNRECOGNIZED;
  }
}

export function directionToJSON(object: Direction): string {
  switch (object) {
    case Direction.INWARD:
      return "INWARD";
    case Direction.OUTWARD:
      return "OUTWARD";
    case Direction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Messages that can only be sent from the device server */
export interface Server {
  feedback?: Server_Movement | undefined;
  stop?: Server_Stop | undefined;
  button?: Server_Button | undefined;
  error?: Server_Error | undefined;
}

/** Linear actuator movement feedback */
export interface Server_Movement {
  direction: Direction;
  speed: number;
}

export interface Server_Stop {
  /**
   * Range from 0 to 100
   * Should not be very precise
   * so range bounds could be easily determined
   */
  position: number;
}

/** Physical button press event */
export interface Server_Button {
  name: Server_Button_Name;
  type: Server_Button_State;
}

export enum Server_Button_Name {
  BOLUS = 0,
  POWER = 1,
  UNRECOGNIZED = -1,
}

export function server_Button_NameFromJSON(object: any): Server_Button_Name {
  switch (object) {
    case 0:
    case "BOLUS":
      return Server_Button_Name.BOLUS;
    case 1:
    case "POWER":
      return Server_Button_Name.POWER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Server_Button_Name.UNRECOGNIZED;
  }
}

export function server_Button_NameToJSON(object: Server_Button_Name): string {
  switch (object) {
    case Server_Button_Name.BOLUS:
      return "BOLUS";
    case Server_Button_Name.POWER:
      return "POWER";
    case Server_Button_Name.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Server_Button_State {
  DOWN = 0,
  PRESS = 1,
  UP = 2,
  UNRECOGNIZED = -1,
}

export function server_Button_StateFromJSON(object: any): Server_Button_State {
  switch (object) {
    case 0:
    case "DOWN":
      return Server_Button_State.DOWN;
    case 1:
    case "PRESS":
      return Server_Button_State.PRESS;
    case 2:
    case "UP":
      return Server_Button_State.UP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Server_Button_State.UNRECOGNIZED;
  }
}

export function server_Button_StateToJSON(object: Server_Button_State): string {
  switch (object) {
    case Server_Button_State.DOWN:
      return "DOWN";
    case Server_Button_State.PRESS:
      return "PRESS";
    case Server_Button_State.UP:
      return "UP";
    case Server_Button_State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Server_Error {
  message: string;
}

/** Messages that can only be sent from browser client */
export interface Client {
  move?: Client_Move | undefined;
  stop?: Client_Stop | undefined;
  cycle?: Client_Cycle | undefined;
}

export enum Client_Motor {
  MAIN = 0,
  CLAMP = 1,
  HEAD = 2,
  UNRECOGNIZED = -1,
}

export function client_MotorFromJSON(object: any): Client_Motor {
  switch (object) {
    case 0:
    case "MAIN":
      return Client_Motor.MAIN;
    case 1:
    case "CLAMP":
      return Client_Motor.CLAMP;
    case 2:
    case "HEAD":
      return Client_Motor.HEAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Client_Motor.UNRECOGNIZED;
  }
}

export function client_MotorToJSON(object: Client_Motor): string {
  switch (object) {
    case Client_Motor.MAIN:
      return "MAIN";
    case Client_Motor.CLAMP:
      return "CLAMP";
    case Client_Motor.HEAD:
      return "HEAD";
    case Client_Motor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Commands the linear actuator to move in a specified direction */
export interface Client_Move {
  direction: Direction;
  speed: number;
  motor: Client_Motor;
}

export interface Client_Stop {
}

export interface Client_Cycle {
}

function createBaseServer(): Server {
  return { feedback: undefined, stop: undefined, button: undefined, error: undefined };
}

export const Server = {
  encode(message: Server, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.feedback !== undefined) {
      Server_Movement.encode(message.feedback, writer.uint32(10).fork()).ldelim();
    }
    if (message.stop !== undefined) {
      Server_Stop.encode(message.stop, writer.uint32(18).fork()).ldelim();
    }
    if (message.button !== undefined) {
      Server_Button.encode(message.button, writer.uint32(26).fork()).ldelim();
    }
    if (message.error !== undefined) {
      Server_Error.encode(message.error, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Server {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.feedback = Server_Movement.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stop = Server_Stop.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.button = Server_Button.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.error = Server_Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Server {
    return {
      feedback: isSet(object.feedback) ? Server_Movement.fromJSON(object.feedback) : undefined,
      stop: isSet(object.stop) ? Server_Stop.fromJSON(object.stop) : undefined,
      button: isSet(object.button) ? Server_Button.fromJSON(object.button) : undefined,
      error: isSet(object.error) ? Server_Error.fromJSON(object.error) : undefined,
    };
  },

  toJSON(message: Server): unknown {
    const obj: any = {};
    if (message.feedback !== undefined) {
      obj.feedback = Server_Movement.toJSON(message.feedback);
    }
    if (message.stop !== undefined) {
      obj.stop = Server_Stop.toJSON(message.stop);
    }
    if (message.button !== undefined) {
      obj.button = Server_Button.toJSON(message.button);
    }
    if (message.error !== undefined) {
      obj.error = Server_Error.toJSON(message.error);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Server>, I>>(base?: I): Server {
    return Server.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Server>, I>>(object: I): Server {
    const message = createBaseServer();
    message.feedback = (object.feedback !== undefined && object.feedback !== null)
      ? Server_Movement.fromPartial(object.feedback)
      : undefined;
    message.stop = (object.stop !== undefined && object.stop !== null)
      ? Server_Stop.fromPartial(object.stop)
      : undefined;
    message.button = (object.button !== undefined && object.button !== null)
      ? Server_Button.fromPartial(object.button)
      : undefined;
    message.error = (object.error !== undefined && object.error !== null)
      ? Server_Error.fromPartial(object.error)
      : undefined;
    return message;
  },
};

function createBaseServer_Movement(): Server_Movement {
  return { direction: 0, speed: 0 };
}

export const Server_Movement = {
  encode(message: Server_Movement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.direction !== 0) {
      writer.uint32(8).int32(message.direction);
    }
    if (message.speed !== 0) {
      writer.uint32(16).int32(message.speed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Server_Movement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServer_Movement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.speed = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Server_Movement {
    return {
      direction: isSet(object.direction) ? directionFromJSON(object.direction) : 0,
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
    };
  },

  toJSON(message: Server_Movement): unknown {
    const obj: any = {};
    if (message.direction !== 0) {
      obj.direction = directionToJSON(message.direction);
    }
    if (message.speed !== 0) {
      obj.speed = Math.round(message.speed);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Server_Movement>, I>>(base?: I): Server_Movement {
    return Server_Movement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Server_Movement>, I>>(object: I): Server_Movement {
    const message = createBaseServer_Movement();
    message.direction = object.direction ?? 0;
    message.speed = object.speed ?? 0;
    return message;
  },
};

function createBaseServer_Stop(): Server_Stop {
  return { position: 0 };
}

export const Server_Stop = {
  encode(message: Server_Stop, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.position !== 0) {
      writer.uint32(8).int32(message.position);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Server_Stop {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServer_Stop();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.position = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Server_Stop {
    return { position: isSet(object.position) ? globalThis.Number(object.position) : 0 };
  },

  toJSON(message: Server_Stop): unknown {
    const obj: any = {};
    if (message.position !== 0) {
      obj.position = Math.round(message.position);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Server_Stop>, I>>(base?: I): Server_Stop {
    return Server_Stop.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Server_Stop>, I>>(object: I): Server_Stop {
    const message = createBaseServer_Stop();
    message.position = object.position ?? 0;
    return message;
  },
};

function createBaseServer_Button(): Server_Button {
  return { name: 0, type: 0 };
}

export const Server_Button = {
  encode(message: Server_Button, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== 0) {
      writer.uint32(8).int32(message.name);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Server_Button {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServer_Button();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.name = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Server_Button {
    return {
      name: isSet(object.name) ? server_Button_NameFromJSON(object.name) : 0,
      type: isSet(object.type) ? server_Button_StateFromJSON(object.type) : 0,
    };
  },

  toJSON(message: Server_Button): unknown {
    const obj: any = {};
    if (message.name !== 0) {
      obj.name = server_Button_NameToJSON(message.name);
    }
    if (message.type !== 0) {
      obj.type = server_Button_StateToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Server_Button>, I>>(base?: I): Server_Button {
    return Server_Button.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Server_Button>, I>>(object: I): Server_Button {
    const message = createBaseServer_Button();
    message.name = object.name ?? 0;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseServer_Error(): Server_Error {
  return { message: "" };
}

export const Server_Error = {
  encode(message: Server_Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Server_Error {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServer_Error();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Server_Error {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: Server_Error): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Server_Error>, I>>(base?: I): Server_Error {
    return Server_Error.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Server_Error>, I>>(object: I): Server_Error {
    const message = createBaseServer_Error();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseClient(): Client {
  return { move: undefined, stop: undefined, cycle: undefined };
}

export const Client = {
  encode(message: Client, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.move !== undefined) {
      Client_Move.encode(message.move, writer.uint32(10).fork()).ldelim();
    }
    if (message.stop !== undefined) {
      Client_Stop.encode(message.stop, writer.uint32(18).fork()).ldelim();
    }
    if (message.cycle !== undefined) {
      Client_Cycle.encode(message.cycle, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Client {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.move = Client_Move.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stop = Client_Stop.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cycle = Client_Cycle.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Client {
    return {
      move: isSet(object.move) ? Client_Move.fromJSON(object.move) : undefined,
      stop: isSet(object.stop) ? Client_Stop.fromJSON(object.stop) : undefined,
      cycle: isSet(object.cycle) ? Client_Cycle.fromJSON(object.cycle) : undefined,
    };
  },

  toJSON(message: Client): unknown {
    const obj: any = {};
    if (message.move !== undefined) {
      obj.move = Client_Move.toJSON(message.move);
    }
    if (message.stop !== undefined) {
      obj.stop = Client_Stop.toJSON(message.stop);
    }
    if (message.cycle !== undefined) {
      obj.cycle = Client_Cycle.toJSON(message.cycle);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Client>, I>>(base?: I): Client {
    return Client.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Client>, I>>(object: I): Client {
    const message = createBaseClient();
    message.move = (object.move !== undefined && object.move !== null)
      ? Client_Move.fromPartial(object.move)
      : undefined;
    message.stop = (object.stop !== undefined && object.stop !== null)
      ? Client_Stop.fromPartial(object.stop)
      : undefined;
    message.cycle = (object.cycle !== undefined && object.cycle !== null)
      ? Client_Cycle.fromPartial(object.cycle)
      : undefined;
    return message;
  },
};

function createBaseClient_Move(): Client_Move {
  return { direction: 0, speed: 0, motor: 0 };
}

export const Client_Move = {
  encode(message: Client_Move, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.direction !== 0) {
      writer.uint32(8).int32(message.direction);
    }
    if (message.speed !== 0) {
      writer.uint32(16).int32(message.speed);
    }
    if (message.motor !== 0) {
      writer.uint32(24).int32(message.motor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Client_Move {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClient_Move();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.speed = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.motor = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Client_Move {
    return {
      direction: isSet(object.direction) ? directionFromJSON(object.direction) : 0,
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      motor: isSet(object.motor) ? client_MotorFromJSON(object.motor) : 0,
    };
  },

  toJSON(message: Client_Move): unknown {
    const obj: any = {};
    if (message.direction !== 0) {
      obj.direction = directionToJSON(message.direction);
    }
    if (message.speed !== 0) {
      obj.speed = Math.round(message.speed);
    }
    if (message.motor !== 0) {
      obj.motor = client_MotorToJSON(message.motor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Client_Move>, I>>(base?: I): Client_Move {
    return Client_Move.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Client_Move>, I>>(object: I): Client_Move {
    const message = createBaseClient_Move();
    message.direction = object.direction ?? 0;
    message.speed = object.speed ?? 0;
    message.motor = object.motor ?? 0;
    return message;
  },
};

function createBaseClient_Stop(): Client_Stop {
  return {};
}

export const Client_Stop = {
  encode(_: Client_Stop, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Client_Stop {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClient_Stop();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Client_Stop {
    return {};
  },

  toJSON(_: Client_Stop): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Client_Stop>, I>>(base?: I): Client_Stop {
    return Client_Stop.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Client_Stop>, I>>(_: I): Client_Stop {
    const message = createBaseClient_Stop();
    return message;
  },
};

function createBaseClient_Cycle(): Client_Cycle {
  return {};
}

export const Client_Cycle = {
  encode(_: Client_Cycle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Client_Cycle {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClient_Cycle();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Client_Cycle {
    return {};
  },

  toJSON(_: Client_Cycle): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Client_Cycle>, I>>(base?: I): Client_Cycle {
    return Client_Cycle.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Client_Cycle>, I>>(_: I): Client_Cycle {
    const message = createBaseClient_Cycle();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
